export const t = {
  more: 'more info...',
  navbar: {
    competences: 'competencies',
    contact: 'contact'
  },
  intro: {
    text: 'Base10 understands itself as the point of intersection of various multi-medial competencies.' +
      ' A project dealt with at 360°, combining practical know-how, creativity, and a solution orientated work ethic,' +
      ' thus creating a holistic, strong product on the whole.'
  },
  creation: {
    heading: 'creation',
    text: 'We will plan your project with you, starting from the design to a website optimized for search engines. ' +
      'Through early prototypes and quick sketches we can find bugs in the early stages of the planning process.'
  },
  development: {
    heading: 'development',
    text: 'In an iterative process we will implement your wishes with our expertise.' +
      ' Constant feedback from our clients is important to us, so that we can create a unique product.'
  },
  maintenance: {
    heading: 'support',
    text: 'A successful web-presence requires constant upkeep, updates, and monitoring. ' +
      'We will take care of that, so that you can follow your profession.'
  },
  competencies: {
    heading: 'competencies',
  },
  contact: {
    heading: 'hello!',
    yourMail: 'your.email@address.com',
    yourMessage: 'Your Message',
    vienna: 'Vienna',
    digitalAgency: 'digtal agency',
    softwareDevelopment: 'software development',
    send: 'send',
    error: {
      noMail: 'No Email address',
      invalidMail: 'Invalid Email address',
      noMessage: 'No Message',
    },
    success: 'Thanks, we will contact you!'
  }
};
