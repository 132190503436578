export const t = {
  more: 'erfahren sie mehr...',

  navbar: {
    competences: 'leistungen',
    contact: 'kontakt'
  },
  intro: {
    text: 'Base10 versteht sich als Schnittstelle diverser multimedialer Kompetenzen.\n' +
      'Eine Projektabwicklung in 360° wird durch praktisches Know-how, Kreativität und' +
      ' lösungsorientiertes Arbeiten verknüpft und lässt ein ganzheitliches, starkes Gesamtprodukt entstehen.'
  },
  creation: {
    heading: 'kreation',
    text: 'Wir planen mit Ihnen Ihr neues Projekt, vom Design bis zur suchmaschinen&shy;optimierten Webseite. ' +
      'Durch frühe Prototypen und schnelle Entwürfe, lassen sich Fehler in der Konzeption schon im Planungsprozess erkennen.'
  },
  development: {
    heading: 'development',
    text: 'In einem iterativen Prozess setzen wir ihre Wünsche mit unserer Expertise um.' +
      ' Ständiges Feedback unserer Kunden ist uns wichtig, damit wir maßgeschneiderte Produkte mit hoher Qualität umsetzen.'
  },
  maintenance: {
    heading: 'betreuung',
    text: 'Ein gelungener Web-Auftritt braucht Wartung, Updates und Monitoring.' +
      'Wir kümmern uns um diese, damit sie ihrer Profession nachgehen können.'
  },
  competencies: {
    heading: 'kompetenzen',
  },
  contact: {
    heading: 'hallo!',
    yourMail: 'ihre.email@addresse.at',
    yourMessage: 'Ihre Nachricht',
    vienna: 'Wien',
    digitalAgency: 'Digitalagentur',
    softwareDevelopment: 'Softwareentwicklung',
    send: 'senden',
    error: {
      noMail: 'Keine Email angegeben',
      invalidMail: 'Ungültige Emailaddresse',
      noMessage: 'Keine Nachricht angegeben',
    },
    success: 'Danke, wir melden uns bei ihnen!'
  }
};
