import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';

import {AppRoutingModule} from './app-routing.module';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,

    TranslateModule.forRoot(),
    ScrollToModule.forRoot(),
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: LOCALE_ID, useValue: 'de'}
  ],
})
export class AppModule {
}
