import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [

  {
    path: 'home',
    loadChildren: './page/home/home.module#HomeModule',
    data: {title: 'Base10'},
  },
  {
    path: 'dsgvo',
    loadChildren: './page/dsgvo/dsgvo.module#DsgvoModule',
    data: {title: 'Dsgvo'},
    canActivate: []
  },
  {
    path: 'imprint',
    loadChildren: './page/imprint/imprint.module#ImprintModule',
    data: {title: 'Impressum'},
    canActivate: []
  },
  // {
  //   path: 'showcase',
  //   loadChildren: './page/showcase/showcase.module#ShowcaseModule',
  //   data: {title: 'showcase'},
  //   canActivate: []
  // },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
