import { Routes } from '@angular/router';
var ɵ0 = { title: 'Base10' }, ɵ1 = { title: 'Dsgvo' }, ɵ2 = { title: 'Impressum' };
var routes = [
    {
        path: 'home',
        loadChildren: './page/home/home.module#HomeModule',
        data: ɵ0,
    },
    {
        path: 'dsgvo',
        loadChildren: './page/dsgvo/dsgvo.module#DsgvoModule',
        data: ɵ1,
        canActivate: []
    },
    {
        path: 'imprint',
        loadChildren: './page/imprint/imprint.module#ImprintModule',
        data: ɵ2,
        canActivate: []
    },
    // {
    //   path: 'showcase',
    //   loadChildren: './page/showcase/showcase.module#ShowcaseModule',
    //   data: {title: 'showcase'},
    //   canActivate: []
    // },
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: 'home'
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
