import {AfterContentInit, AfterViewChecked, Component, ElementRef, EventEmitter, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import {t as en} from './translations/en.translation';
import {t as de} from './translations/de.translation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {

  constructor(private translateService: TranslateService, public el: ElementRef) {

    translateService.onLangChange.subscribe((e: any) => {
      const lang = document.createAttribute('lang');
      lang.value = this.translateService.currentLang;
      this.el.nativeElement.parentElement.parentElement.attributes.setNamedItem(lang);

    });

    registerLocaleData(localeDe);
    registerLocaleData(localeEn);
    translateService.setDefaultLang('de');
    translateService.setTranslation('en', en);
    translateService.setTranslation('de', de);
    translateService.use('de');
  }


}
